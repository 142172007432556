<template>
	<div class="mainChatContainer">
		<Loading v-if="isLoading" />
		<GTranslate></GTranslate>
		<section>
			<chat-window
				v-if="!isLoading && !error"
				ref="chat"
				height="100vh"
				:current-user-id="currentSenderId"
				:rooms="rooms"
				:messages-loaded="messagesLoaded"
				:messages="messagesForChat"
				:single-room="true"
				:room-id="1"
				:rooms-loaded="true"
				:room-loaded="true"
				:message-actions="[]"
				:show-files="false"
				:show-audio="false"
				:show-reaction-emojis="false"
				:show-footer="showFooter"
				@send-message="sendMessage"
				@fetch-messages="fetchMessages"
				@typing-message="writing"
			>
				<template v-slot:room-header="{}" >
					<v-btn icon @click="$router.replace('/chat')" text><v-icon>mdi-arrow-left</v-icon></v-btn>
					<span class="ml-2 notranslate">{{ clientFullName }}</span>
					<a class="text-primary ml-2 notranslate" @click="goToBooking" href="#">  <small>#{{ transportService.serviceNumber}}</small></a>
				</template>
			</chat-window>
		</section>
	</div>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import { auth, firestore, firebase } from "../plugins/firebase";
import GTranslate from "../components/gTranslate.vue";
const Loading = () =>
	import(/* webpackChunkName: "Loading" */ "@/components/loading.vue");

const modelForMessage = {
	_id: -1,
	content: "",
	senderId: -1,
	username: "",
	avatar: "",
	date: "",
	timestamp: "",
	system: false,
	saved: false,
	distributed: false,
	disableActions: false,
	disableReactions: false,
	seen: false,
};

const modelForMessageToSend = {
	id: 0,
	seen: false,
	createdAt: "",
	content: 1,
	userType: "",
	userId: -1,
};

const roomsList = [
	{
		roomId: 1,
		roomName: "",
		avatar: "assets/imgs/people.png",
		index: 1,
	},
];


const modelNewRoom= {
	clientNewMessage:false,
	driverNewMessage:false,
	members:{client:0,driver:0},
	lastUpdated:null,
	room: '',
	client:{firstName:"",lastName:""},
	serviceNumber:"",
	createdAt: null,
	clientToken: "",
};

const modelUpdateRoom= {	
	lastUpdated:null
}
const modelTs ={
	reservation:{id:-1,serviceNumber:"",client:{id:-1,firstName:'',lastName:''}}
}
export default {
	name: "ChatView",
	components: { Loading, ChatWindow, GTranslate },
	data() {
		return {
			error: false,
			formats: {
				time: "HH:mm",
				date: "dddd, D MMM YYYY",
			},
			isLoading: false,
			messagesForChat: [],
			messages: [],
			messagesLoaded: false,
			messagesLoading: false,
			currentService: -1,
			rooms: [...roomsList],
			db: {
				room: null,
			},
			me:"",
			fcmTokens:{client:null},			
			listener:null,
			listenerForToken:null,
			userType: "driver",
			transportService: { ...modelTs },
			messagesVisible: 20,
			loaded: false,
			referenceContainer: null,
		};
	},
	methods: {
		...mapActions("ui", {
			disabledNavigation: "disabledNavigation",
			activeBackBtn: "activeBackBtn",
		}),
		goToBooking() {
			// Dar un poco de tiempo para mirar efecto
			setTimeout(() => {
				let rideId = this.transportService.id;
				this.$router.push({ name: "booking",params:{id:rideId}});
			}, 250);
		},
		goToBottom() {
			this.referenceContainer.scrollTo(
				0,
				this.referenceContainer.scrollHeight
			);
		},
		writing() {
			if (this.messagesForChat.length > this.messagesVisible) {
				this.messagesForChat = [
					...this.messages.slice(-this.messagesVisible),
				];
			}
			this.goToBottom();
		},
		fetchMessages({ options }) {
			//
			let opening = options?.reset ?? false; //si esta abriendo el room
			this.messagesLoaded = false;
			this.messagesForChat = [];
			if (opening) {
				this.takeMessagesVisibles();
			} else {
				//scrolling up (quieren ver todos los mensajes)
				this.messagesForChat = [...this.messages]; //se muestran todos los mensajes
				setTimeout(() => {
					this.messagesLoaded = true;
				});
			}
		},
		takeMessagesVisibles() {
			let copy = [...this.messages];
			this.messagesForChat = [...copy.slice(-this.messagesVisible)];
		},
		canBeWritten(){		
			return (this.userId >0 && (this.userType?? "") != "" && this.client.id>0)
		},
		getClientToken(){
			this.listenerForToken =firestore.collection("chats").doc(this.roomName).onSnapshot((_doc)=>{
				if(_doc.exists){
					let token = _doc.data().clientToken;						
					if(token && token != this.fcmTokens.client){//si se cambia el token								
						this.fcmTokens.client = token;
					}
				}
			})
		},
		sendPushNotification(messageNode){
			let send = true;
			if ((this.fcmTokens.client ?? null) !== null || send) {						
				let data = {
					serviceId: this.transportService.id,
					app: "driver",
					title: this.myself.firstName+":",
					text: messageNode.content,
				};				
				this.$axios.post("driver/notifications/send/" +this.fcmTokens.client,data).catch((err)=>{
					console.log(err)
				})
			}
		},
		sendMessage(messageNode) {
			const servertTimeStamp = firebase.firestore.FieldValue.serverTimestamp();
			new Promise((next) => {
				if (!this.canBeWritten()) {
					throw "forbidden";
				}
				if(this.roomExists){				
					let roomUpdate = {...modelUpdateRoom}		
					roomUpdate.lastUpdated ={...servertTimeStamp}	
					roomUpdate[this.userType+"NewMessage"] = true;					
					this.db.room.update(roomUpdate,{merge:true}).then(next(this.db.room.collection("messages")))
				}else{					
					let room = {...modelNewRoom}				
					room.members.client  =  parseInt(this.client.id)
					room.members.driver  =  parseInt(this.userId)
					room.room             = this.roomName,
					room.client.firstName = this.client.firstName;
					room.client.lastName  = this.client.lastName;
					room.serviceNumber    = this.transportService.serviceNumber
					room.createdAt   = {...servertTimeStamp};
					room.lastUpdated ={...servertTimeStamp}						
					room[this.userType+"NewMessage"] = true;									
					this.db.room.set(room).then(()=>{
						next(this.db.room.collection("messages"))
					})
				}
			})
				.then((messagesRef) => {
					let message = messagesRef.doc();
					let model = { ...modelForMessageToSend };
					model.id = message.id;
					model.userType = this.userType;
					model.userId = this.userId;
					model.content = messageNode.content;
					model.createdAt = servertTimeStamp;
					return message.set(model);
				})
				.then(() => {
					this.takeMessagesVisibles();
					this.sendPushNotification(messageNode);//to client
				})
				.catch((err) => {
					console.log(err);
				});
		},
		updateMessage(snapshot) {
			try {
				let messageData = snapshot.doc.data();
				let metadata = snapshot.doc.metadata;
				if (metadata.hasPendingWrites === false) {
					let messageId = messageData.id;
					let totalItems = this.messagesForChat.length ?? 0;
					for (let _i = totalItems - 1; _i >= 0; _i--) {
						if (this.messagesForChat[_i]._id == messageId) {
							let newMessage = this.fillModelMessage(
								snapshot.doc
							);
							Object.assign(this.messagesForChat[_i], newMessage);
							return;
						}
					}
				}
			} catch (err) {
				console.log("error catching change: " + err);
			}
		},

		fillModelMessage(data) {
			let source =
				data.metadata.hasPendingWrites ?? true ? "local" : "server";
			let item = data.data();
			let model = { ...modelForMessage };
			model.senderId = item.userType + "-" + item.userId;
			model.content = item.content;
			model._id = data.id;
			let createdAt =
				item.createdAt === null
					? this.$moment()
					: item.createdAt.toDate();
			model.date = this.$moment(createdAt)
				.locale("es")
				.format(this.formats.date);
			model.timestamp = this.$moment(createdAt)
				.locale("es")
				.format(this.formats.time);
			model.seen = model.senderId != this.currentSenderId;
			if (source === "local") {				
				model.saved = false;
			} else {				
				model.saved = true;
			}
			return model;
		},
		initChatRoom(){			
			new Promise((ok,reject)=>{
				auth.onAuthStateChanged(user=>{
					if(user){						
						ok(user)
					}else{
						reject("no logged for chat")
					}		
				})
			})
			.then((user)=>{			
				this.me            = user.uid;				
				return this.$axios.get(`driver/transport-services/${this.$route.params.id}`)
			})
			.then(res => {				
				this.transportService  = {...res.data}			
				return new Promise(next=>{
					firestore.collection("chats").doc(this.roomName).get()
					.then((_doc)=>{
						this.roomExists = _doc.exists							
						this.db.room    = firestore.collection("chats").doc(this.roomName)
						if(this.roomExists){
							this.db.room.update({clientNewMessage:false},{merge:true});	//seen by driver																		
						}
						this.getClientToken()
						next();
					})
				})										
			})
			.then(() => {//listener
				return new Promise(next=>{
					this.listener = this.db.room.collection("messages").orderBy("createdAt","asc")
					.onSnapshot({includeMetadataChanges: true},(doc) => {
						doc.docChanges({source:"server"}).forEach((change)=> {							
							if (change.type === "added") {
								let messageFetched = this.fillModelMessage(change.doc)
								this.messages.push(messageFetched)
							}
							if (change.type === "modified") {//se detecta cuando se escribe en back
								this.updateMessage(change)
							}
							this.db.room.update({clientNewMessage:false},{merge:true});	//seen by driver
							this.takeMessagesVisibles()
						});
						next()
					})
				})				
			})					
			.then(()=>{
				this.loaded = true;		
				this.messagesLoaded  = true;
				this.getClientToken();
			})
			.catch((err)=>{
				this.error = true;
				console.log(err);							
			})				
			.finally(() => {
				this.isLoading = false;
				this.takeMessagesVisibles();
			});
		},
	},
	computed: {     	
		roomName(){
			return String(this.transportService.id)
		},		
		clientFullName(){		
			return this.client?.firstName+" "+this.client?.lastName
		},
		showFooter() {
			return this.loaded && this.canBeWritten();
		},
		userId() {
			return store.state.auth.user.id;
		},
		client() {
			return this.transportService.reservation.client;
		},
		myself(){
			return store.state.auth.user;
		},
		currentSenderId(){
			return this.userType+"-"+this.userId
		}
	},
	mounted() {
		// Disabled navigation
		this.disabledNavigation();
		// =>
		this.activeBackBtn();
		this.initChatRoom();

		let el = this.$refs["chat"].$el.getElementsByClassName(
			"vac-container-scroll"
		)[0];
		this.referenceContainer = el;
		el.addEventListener("scroll", () => {
			if (this.messagesLoaded) {
				if (el.scrollTop <= 0) {
					this.messagesForChat = [...this.messages];
				}
			}
			//alert("scroll")
		});
	},
	destroyed: function() {
		if (this.listener) {
			this.listener(); //desvincular agente de escucha
		}
		if(this.listenerForToken){
			this.listenerForToken()
		}
	},
};
</script>

<style>
.vac-card-window {
	height: calc(100vh - (49px + 40px)) !important;
}

.mainChatContainer {
	overflow-x: hidden !important;
	position: fixed;
	width: 100%;
}

@media only screen and (max-width: 768px) {
    .vac-box-footer {
        z-index: 4;
		position: fixed !important;
		bottom: 0;
		width: 100%;
    }

	.vac-app-border-b {
		z-index: 4;
		position: fixed !important;
		/*top: 48px;*/
		width: 100%;
	}

	.vac-messages-container {
		margin-bottom: 120px !important;
	}
}

</style>
